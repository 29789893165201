// State
export interface PageState {
    windowWidth: number;
    windowHeight: number;
    headerOpened: boolean;
    siteMapOpened: boolean;
}


// Action識別子
export const UPDATE_WINDOW_WIDTH = "UPDATE_WINDOW_WIDTH"
export const UPDATE_WINDOW_HEIGHT = "UPDATE_WINDOW_HEIGHT"
export const UPDATE_HEADER_OPENED = "UPDATE_HEADER_OPENED"
export const UPDATE_SITEMAP_OPENED = "UPDATE_SITEMAP_OPENED"


// Action Creator
export interface UpdateWindowWidthAction {
    type: typeof UPDATE_WINDOW_WIDTH
    payload: PageState
}

export interface UpdateWindowHeightAction {
    type: typeof UPDATE_WINDOW_HEIGHT
    payload: PageState
}

export interface UpdateHeaderOpenedAction {
    type: typeof UPDATE_HEADER_OPENED
    payload: PageState
}

export interface UpdateSiteMapOpenedAction {
    type: typeof UPDATE_SITEMAP_OPENED
    payload: PageState
}


// Action Types
export type PageActionTypes = UpdateWindowWidthAction | UpdateWindowHeightAction | UpdateHeaderOpenedAction | UpdateSiteMapOpenedAction