import {
    PageActionTypes,
    PageState,
    UPDATE_WINDOW_WIDTH,
    UPDATE_WINDOW_HEIGHT,
    UPDATE_HEADER_OPENED,
    UPDATE_SITEMAP_OPENED,
} from "./types"

const initialState: PageState = {
    windowWidth: 0,
    windowHeight: 0,
    headerOpened: false,
    siteMapOpened: false,
}

export function pageReducer(
    state = initialState,
    action: PageActionTypes,
): PageState {
    switch (action.type) {
        case UPDATE_WINDOW_WIDTH: {
            return {
                ...state,
                ...action.payload,
            }
        }
        case UPDATE_WINDOW_HEIGHT: {
            return {
                ...state,
                ...action.payload,
            }
        }
        case UPDATE_HEADER_OPENED: {
            return {
                ...state,
                ...action.payload,
            }
        }
        case UPDATE_SITEMAP_OPENED: {
            return {
                ...state,
                ...action.payload,
            }
        }
        default:
            return state
    }
}
