import React, { useState, useEffect, Fragment } from "react"
import PropTypes from "prop-types"
import { Tween } from "react-gsap"
import { useCookies } from "react-cookie"


/**
 * CookieUseNotification Component
 * Cookieの利用通知
 *
 * このコンポーネントは、クッキーを設定する機能のみを提供します。
 *
 * クッキーの設定に基づいてGoogleアナリティクス等のサービスを有効化するには、
 * gatsby-plugin-gdpr-cookies と一緒に使用します。
 * https://github.com/andrezimpel/gatsby-plugin-gdpr-cookies
 *
 *
 * @param {number} delay - 通知を表示するまでの遅延時間 (default: 3秒)
 * @return {*} React Element
 */
interface ICookieUseNotificationDefaultProps {
    children?: any,
    delay: number,
}

const CookieUseNotification = (props: ICookieUseNotificationDefaultProps) => {
    // クッキーを保持する期間
    const holdingPeriod = 30
    // Googleアナリティクスを有効化するクッキー名
    const cookieNameGA = "tb_ga"

    const [googleAnalyticsChecked, setGoogleAnalyticsChecked] = useState(true)
    const [retainSettingsChecked, setRetainSettingsChecked] = useState(false)
    const [cookieIsSet, setCookieIsSet] = useState(false)
    const [isClose, setIsClose] = useState<boolean>(false)

    const [cookies, setCookie, removeCookie] = useCookies([cookieNameGA])


    useEffect(() => {
        if (Object.keys(cookies).length <= 0) return

        if (cookieNameGA in cookies) setCookieIsSet(true)
    }, [])


    // クッキーを設定する
    const settingCookies = () => {

        // クッキーを破棄する日付
        const today: Date = new Date()
        let tomorrow: Date = new Date()
        tomorrow.setDate(today.getDate() + holdingPeriod)

        // オプション
        type TOptions = {
            path: string,
            expires?: Date,
        }
        let options: TOptions = {
            path: "/",
        }
        // クッキーを保持するにチェックがあれば期限を設定する
        if (retainSettingsChecked) options.expires = tomorrow

        // Googleアナリティクスのクッキーを設定する
        setCookie(cookieNameGA, googleAnalyticsChecked, options)
    }


    const onClickGoogleAnalytics = () => {
        setGoogleAnalyticsChecked(!googleAnalyticsChecked)
    }


    const onClickRetainSettings = () => {
        setRetainSettingsChecked(!retainSettingsChecked)
    }


    const closeNotification = () => {
        settingCookies()
        setIsClose(true)
    }


    return (
        <Fragment>
            {props.children}
            <Tween
                from={{
                    bottom: isClose ? "0" : "-500px",
                }}
                to={{
                    bottom: isClose ? "-500px" : "0",
                }}
                duration={0.5}
                delay={props.delay}
            >
                <div
                    className="cookie-use-notification"
                    style={{
                        display: cookieIsSet ? "none" : "block",
                    }}
                >
                    <div className="grid-container">
                        <div className="grid-x">
                            <div className="cell">
                                <p>このWebサイトでは、お客様が最高の体験を得られるようにCookieを使用したサービスを利用しています。</p>
                            </div>
                        </div>
                        <div className="grid-x grid-margin-x">
                            <div className="cell small-12 medium-7 large-9">
                                <span className="cookie-use-notification__title">Cookieの使用に同意するサービス</span>
                                <div className="cookie-use-notification__service-list">
                                    <ul>
                                        <li>
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    defaultChecked={googleAnalyticsChecked}
                                                    onClick={onClickGoogleAnalytics}
                                                />Googleアナリティクス
                                            </label>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="cell small-12 medium-5 large-3">
                                <div className="cookie-use-notification__hold">
                                    <label>
                                        <input
                                            type="checkbox"
                                            defaultChecked={retainSettingsChecked}
                                            onClick={onClickRetainSettings}
                                        />設定を保持する（{holdingPeriod}日間）
                                    </label>
                                </div>
                                <a
                                    className="button"
                                    style={{ width: "100%" }}
                                    onClick={closeNotification}
                                >
                                    閉じる
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </Tween>
        </Fragment>
    )
}

CookieUseNotification.defaultProps = {
    delay: 3,
}


export default CookieUseNotification