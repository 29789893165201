import React, { useEffect } from "react"
import { Provider } from "react-redux"
import configureStore from "./src/store"
import { CookiesProvider } from "react-cookie"


// Components
import CookieUseNotification from "./src/components/CookieUseNotification"


interface IWrapWithProviderDefaultProps {
    element: any,
}

const WrapWithProvider = (props: IWrapWithProviderDefaultProps) => {
    const store = configureStore()

    return (
        <Provider store={store}>
            <CookiesProvider>
                <CookieUseNotification>
                    {props.element}
                </CookieUseNotification>
            </CookiesProvider>
        </Provider>
    )
}

export default WrapWithProvider