module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"UA-18007756-1","cookieName":"tb_ga","anonymize":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"トランスブート株式会社","short_name":"トランスブート","description":"私たちはアイデアを形にするモノづくり企業です","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"standalone","icon":"src/images/tranceboot_icon.png","icons":[{"src":"favicon.ico","sizes":"16x16","type":"image/x-icon"},{"src":"/favicons/favicon24.png","sizes":"24x24","type":"image/png"},{"src":"/favicons/favicon32.png","sizes":"32x32","type":"image/png"},{"src":"/favicons/favicon36.png","sizes":"36x36","type":"image/png"},{"src":"/favicons/favicon48.png","sizes":"48x48","type":"image/png"},{"src":"/favicons/favicon72.png","sizes":"72x72","type":"image/png"},{"src":"/favicons/favicon96.png","sizes":"96x96","type":"image/png"},{"src":"/favicons/favicon192.png","sizes":"192x192","type":"image/png"},{"src":"/favicons/favicon512.png","sizes":"512x512","type":"image/png"}],"cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"52e20b1bf5ec6f14a54377cb78802c23"},
    },{
      plugin: require('../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true,"autoGenHomeLabel":"Home","exclude":[],"crumbLabelUpdates":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
