import { applyMiddleware, combineReducers, createStore } from "redux"
import thunkMiddleware from "redux-thunk"
import { composeWithDevTools } from "redux-devtools-extension"

import { pageReducer } from "./page/reducers"


const rootReducer = combineReducers({
    page: pageReducer,
})


export type AppState = ReturnType<typeof rootReducer>


export default function configureStore() {
    const middlewares = [thunkMiddleware]
    const middleWareEnhancer = applyMiddleware(...middlewares)

    return createStore(
        rootReducer,
        composeWithDevTools(middleWareEnhancer),
    )
}